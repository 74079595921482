@import url(https://fonts.googleapis.com/css2?family=Karla:wght@500;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "Recoleta";
  src: local("Recoleta_Bold"), url(/static/media/Recoleta_Bold.29b7faab.otf) format("OpenType");
}

html {
  font-size: 62.5%;
}

body {
  font-size: 1.6rem;
  margin: 0;
  padding: 0;
  font-family: "Karla", sans-serif;
  line-height: 1.3;
  background: #f5f5f5;
  overflow-x: hidden;
}

html,
body {
  -webkit-tap-highlight-color: transparent;
}

img {
  max-width: 100%;
}

a,
a:active,
a:visited,
a:focus {
  text-decoration: none;
  color: #888888;
  font-weight: bold;
}

a:hover {
  color: #161616;
}

*,
*::before,
*::after {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
  text-rendering: optimizeLegibility;
  box-sizing: border-box;
}

